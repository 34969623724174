.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Gradient Border Button */
.gradient-border-button {
  position: relative;
  padding: 0.6rem 2rem;
  font-size: 1.1rem;
  color: white;
  background: #1e293b;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gradient-border-button::before {
  content: '';
  position: absolute;
  inset: -3px;
  background: linear-gradient(45deg, #60a5fa, #a78bfa, #60a5fa);
  border-radius: 10px;
  z-index: -1;
  transition: opacity 0.3s ease;
  animation: borderRotate 4s linear infinite;
}

@keyframes borderRotate {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

.gradient-border-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(96, 165, 250, 0.4);
}

.slide-content {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  z-index: 1;
}

.icon {
  transition: transform 0.3s ease;
}

/* Premium Cart Button */
.cart-button {
  position: relative;
  padding: 0.6rem 2rem;
  font-size: 1.1rem;
  color: white;
  background: linear-gradient(45deg, #3b82f6, #8b5cf6);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
}

.cart-text, .cart-icon, .cart-check {
  display: inline-block;
  transition: transform 0.5s ease;
}

.cart-icon {
  margin-left: 0.8rem;
  transform: translateX(100%);
  opacity: 0;
}

.cart-check {
  position: absolute;
  right: 1.2rem;
  transform: translateX(100%);
  opacity: 0;
}

.cart-button:hover .cart-text {
  transform: translateX(-10px);
}

.cart-button:hover .cart-icon {
  transform: translateX(0);
  opacity: 1;
}

.cart-button:active .cart-icon {
  transform: translateX(100%);
  opacity: 0;
}

.cart-button:active .cart-check {
  transform: translateX(0);
  opacity: 1;
}

/* Shine Effect Button */
.shine-button {
  position: relative;
  padding: 0.6rem 2rem;
  font-size: 1.1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.shine-content {
  position: relative;
  z-index: 1;
}

.shine-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
  );
  transform: rotate(45deg) translateY(-100%);
  transition: transform 0.5s ease;
}

.shine-button:hover::before {
  transform: rotate(45deg) translateY(100%);
}

/* Border Animation Button */
.border-animation-button {
  position: relative;
  padding: 0.6rem 2rem;
  font-size: 1.1rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.border-animation-button .button-text {
  position: relative;
  z-index: 1;
}

.border-animation-button div {
  position: absolute;
  background: #60a5fa;
}

.border-top, .border-bottom {
  width: 0;
  height: 2px;
  transition: width 0.3s ease;
}

.border-right, .border-left {
  width: 2px;
  height: 0;
  transition: height 0.3s ease;
}

.border-top { top: 0; right: 0; }
.border-right { top: 0; right: 0; }
.border-bottom { bottom: 0; left: 0; }
.border-left { bottom: 0; left: 0; }

.border-animation-button:hover .border-top,
.border-animation-button:hover .border-bottom {
  width: 100%;
}

.border-animation-button:hover .border-right,
.border-animation-button:hover .border-left {
  height: 100%;
}

/* Scale Background Button */
.scale-button {
  position: relative;
  padding: 0.6rem 2rem;
  font-size: 1.1rem;
  color: white;
  background: none;
  border: 2px solid #a78bfa;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
}

.scale-text {
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

.scale-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #a78bfa;
  transform: scale(0);
  transition: transform 0.3s ease;
  z-index: 0;
}

.scale-button:hover .scale-bg {
  transform: scale(1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .button-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .button-wrapper {
    min-height: 120px;
  }
}



  /**/

.minecraft-button {
  font-family: 'Minecraft', sans-serif;
  text-shadow: rgb(6, 77, 42) 0 .1em 0;
  text-decoration: none;
  text-align: center;
  color: white;
  background-color: rgb(0, 140, 69);
  position: relative;
  padding-bottom: .3em;
  border-radius: 2px;
  box-sizing: border-box;
}

.minecraft-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -4px;
  width: calc(100% + 8px);
  height: 100%;
  background-color: rgb(12 110 61);
  z-index: -1;
  border: .1rem solid rgb(40, 18, 18);
  box-sizing: border-box;
}

.minecraft-button::after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: calc(100% + 7px);
  background-image: linear-gradient(
      rgb(39, 206, 64) 50%,
      rgb(6, 77, 42)
  );
  z-index: -1;
  border: .07em solid rgb(40, 18, 18);

  box-sizing: border-box;
}

.minecraft-button:hover {
  background-color: rgb(10, 152, 80);
}
